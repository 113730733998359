import React from 'react'

const Image = ({ image_url, alt, className, id, onLoad }) => {
  let file_name = image_url.split('/').pop()
  const dir_name = image_url.replace(file_name, '')
  file_name = file_name.substr(0,file_name.lastIndexOf('.'))
  const default_ext = image_url.split('.').pop()
  const accepted_extensions = ['png', 'jpeg', 'jpg']
  const path = `${dir_name}${file_name}`
  const default_mobile_path = `${path}--mobile.${default_ext}`

  const tryRequire = (path) => {
    try {
     return require(`images/${path}`).default
    } catch (err) {
      return null
    }
  }

  if (!accepted_extensions.includes(default_ext)) {
    // Extension is outside of PNG JPEG JPG
    return <img src={require(`images/${image_url}`).default} alt={alt} className={className} id={id} onLoad={onLoad}/>
  } else if (tryRequire(default_mobile_path)) {
    // if mobile versions available
    return (
      <picture>
        <source srcSet={require(`images/${path}.webp`).default} type="image/webp" media="(min-width: 768px)" />
        <source srcSet={require(`images/${path}--mobile.webp`).default} type="image/webp" />
        <source srcSet={require(`images/${path}.${default_ext}`).default} type={`image/${default_ext}`} media="(min-width: 768px)" />
        <source srcSet={require(`images/${default_mobile_path}`).default} type={`image/${default_ext}`} />
        <img src={require(`images/${path}.${default_ext}`).default} alt={alt} className={className} id={id} onLoad={onLoad}/>
      </picture>
    )
  } else {
    // mobile versions not available
    return (
      <picture>
        <source srcSet={require(`images/${path}.webp`).default} type="image/webp" />
        <source srcSet={require(`images/${path}.${default_ext}`).default} type={`image/${default_ext}`} />
        <img src={require(`images/${path}.${default_ext}`).default} alt={alt} className={className} id={id} onLoad={onLoad}/>
      </picture>
    )
  }
}

export default Image